<template>
  <div
    :class="[`has-background-${color}`, `UpgradeNowNotice--${type}`]"
    class="UpgradeNowNotice"
  >
    <div class="columns is-marginless is-gapless is-centered">
      <div
        :class="{ 'is-4-desktop is-6-tablet': hasImage}"
        class="column is-flex is-aligned-middle"
      >
        <div class="p-v-l p-l-xxl p-r-xxl">
          <heading
            size="3"
            color=""
          >
            {{ $t(`components.upgrade_now_notice.${type}.heading`) }}
          </heading>
          <p class="m-b-l">
            {{ $t(`components.upgrade_now_notice.${type}.body`) }}
          </p>
          <router-link
            :to="upgradeUrl"
            class="button is-primary is-wide"
          >
            {{ $t('components.upgrade_now_notice.see_more') }}
          </router-link>
        </div>
      </div>
      <div
        v-if="hasImage"
        class="column is-8-desktop is-6-tablet"
      >
        <img :src="imageSrc">
      </div>
    </div>
  </div>
</template>

<script>

/**
 * Renders an UpgradeNow notice element.
 * Used above every section to display some text with an image to upgrade now.
 * @module UpgradeNowNotice
 */
export default {
  name: 'UpgradeNowNotice',
  props: {
    type: {
      type: String,
      required: true
    },
    hasImage: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'info'
    },
    upgradeUrl: {
      type: [Object, String],
      default: () => ({ name: 'settings.billing.billing' })
    }
  },
  computed: {
    imageSrc () {
      return this.$asset(`/images/components/upgrade-now/${this.type}.png`)
    }
  }
}
</script>

<style lang="scss">
// we set this so the Heading can ihnerit the color of the notice text
.UpgradeNowNotice .Heading .title {
  color: inherit;
}
</style>
